import React from 'react';
import styled from '@emotion/styled';

import TitleUnderlined from '../components/TitleUnderlined';
import ButtonUnderlined from '../components/ButtonUnderlined';
import ButtonNotUnderlined from './ButtonNotUnderlined';

const TextBlockContainer = styled.div`
  background-color: #fff;
  width: 100%;
  height: 200px;
  display: flex;
  background-color: #212121;
  @media (max-width: 735px) {
    height: 200px;
    flex-wrap: wrap;
  }
`;

const TextBlockTopLeftText = styled.div`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-top: 32px;
  padding-left: 45px;
`;

const TitleContainer = styled.div`
  max-width: 480px;
  padding-left: 45px;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  margin-top: 10px;
  @media (max-width: 735px) {
    margin-top: 25px;
    max-width: 80%;
  }
`;

const LeftContent = styled.div`
  width: 65%;
  position: relative;
  @media (max-width: 735px) {
    width: 100%;
    height: 70%;
  }
`;

const RightContent = styled.div`
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 50px;
  @media (max-width: 735px) {
    width: 100%;
    height: fit-content;
    justify-content: center;
    margin-left: 45px;
    margin-bottom: 20px;
  }
`;

const TextCard = ({
  props,
  cta_card_subtitle,
  cta_card_title,
  cta_card_button_text,
  cta_card_button_colour,
  cta_card_button_link,
  card_title_underline_color,
  cta_title_underline_color,
  cta_card_button_link_target,
}) => (
  <TextBlockContainer>
    <LeftContent>
      <TextBlockTopLeftText>{cta_card_subtitle}</TextBlockTopLeftText>
      <TitleContainer>
        <TitleUnderlined
          titleText={'<span>' + cta_card_title + '</span>'}
          underlineColor={cta_title_underline_color}
          fontSize="30px"
          tabletFontSize="26px"
          tableLineHeight="28px"
          mobileFontSize="18px"
          mobileLineHeight="22px"
          lineHeight="40px"
          leftAligned={true}
        />
      </TitleContainer>
    </LeftContent>
    <RightContent>
      <ButtonNotUnderlined
        buttonText={cta_card_button_text}
        color={cta_card_button_colour}
        arrowColor={cta_card_button_colour}
        link={cta_card_button_link}
        target={cta_card_button_link_target}
      />
    </RightContent>
  </TextBlockContainer>
);

export default TextCard;
