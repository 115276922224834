import React from 'react';
import Arrow from './assets/arrow.svg';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import linkStripper from '../../utils/link-stripper';
import { Link } from 'gatsby';
import { buttonReset } from '../../utils/styles';

const ButtonNotUnderlinedContainer = styled(motion.div)`
  display: flex;
  border-width: 0px;
  border-style: solid;
  border-color: ${props => props.color};
  width: fit-content;
  margin: ${props => props.margin};
  cursor: pointer;
  align-items: center;
  font-family: Montserrat;
`;

const ButtonText = styled.div`
  font-weight: bold;
  font-size: ${props => props.fontSize};
  @media (max-width: 735px) {
    font-size: ${props => props.fontSizeMobile};
  }
  color: ${props => props.color};
`;

const ArrowContainer = styled(motion.div)`
  margin-left: 10px;
  padding-top: 1px;
  display: flex;
  align-items: center;
`;

const ReadMore = styled(motion.div)`
  transition: ease 300ms all;
  max-height: 0;
  overflow: hidden;
  color: #ffffff;
  font-weight: normal;
`;

const ReadMoreLink = styled(motion.div)`
  display: block;
  text-decoration: underline;
  font-weight: bold;
  color: #ffffff;
  margin-top: 10px;
`;

const ButtonNotUnderlinedContainerInner = styled.div`
  display: flex;
`;

const Button = styled.button`
  ${buttonReset};

  @media (max-width: 735px) {
    text-align: left;
  }
`;

function InnerComponent({ link, target, children }) {
  if (link) {
    return (
      <a href={linkStripper(link)} target={target}>
        {children}
      </a>
    );
  }

  return <Button>{children}</Button>;
}

const ButtonNotUnderlinedReadMore = props => {
  const [active, setActive] = React.useState(false);
  return (
    <div>
      <ButtonNotUnderlinedContainer
        color={props.color}
        margin={props.margin}
        onClick={e => {
          e.preventDefault();
          setActive(!active);
        }}
      >
        <InnerComponent link={props.link} target={props.target}>
          <ButtonNotUnderlinedContainerInner>
            <ButtonText
              color="#ffffff"
              fontSize={props.fontSize ? props.fontSize : '20px'}
              fontSizeMobile={
                props.fontSizeMobile ? props.fontSizeMobile : '18px'
              }
            >
              {props.buttonText}
            </ButtonText>
            <ArrowContainer
              color={props.color}
              animate={active ? { rotate: '90deg' } : { rotate: '0deg' }}
              transition={{ duration: 0.5 }}
            >
              <Arrow
                style={{
                  fill: '#FFF',
                }}
              />
            </ArrowContainer>
          </ButtonNotUnderlinedContainerInner>
        </InnerComponent>
      </ButtonNotUnderlinedContainer>
      <ReadMore
        style={
          active
            ? { opacity: 1, maxHeight: 'fit-content', paddingTop: '10px' }
            : { opacity: 0, maxHeight: 0 }
        }
      >
        {props.readmore}

        {props.link && (
          <ReadMoreLink>
            <a href={linkStripper(props.link)} target={props.target}>
              Read more
            </a>
          </ReadMoreLink>
        )}
      </ReadMore>
    </div>
  );
};

export default ButtonNotUnderlinedReadMore;
