import React from 'react';
import styled from '@emotion/styled';

import convertHtml from '../utils/convertHtml';

const HeaderSubtitleContainer = styled.div`
  font-size: 18px;
  max-width: 450px;
  line-height: 28px;
  margin-top: 30px;
  span {
    font-weight: bold;
    font-style: normal;
  }
`;

const TitleUnderlined = ({ props, subtitle_text }) => (
  <HeaderSubtitleContainer
    dangerouslySetInnerHTML={convertHtml(subtitle_text)}
  ></HeaderSubtitleContainer>
);

export default TitleUnderlined;
