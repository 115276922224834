import React from 'react';
import styled from '@emotion/styled';

import TitleUnderlined from '../components/TitleUnderlined';
import ButtonUnderlined from '../components/ButtonUnderlined';
import ButtonNotUnderlined from './ButtonNotUnderlined';

const TextBlockContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  background-color: #212121;
  @media (max-width: 735px) {
    flex-wrap: wrap;
  }
`;

const TextBlockTopLeftText = styled.div`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-top: 32px;
  padding-left: 15px;
  padding-right: 15px;
`;

const TitleContainer = styled.div`
  max-width: 480px;
  padding-left: 45px;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  margin-top: 10px;
  @media (max-width: 735px) {
    margin-top: 25px;
    max-width: 80%;
  }
`;

const LeftContent = styled.div`
  width: 100%;
  position: relative;
`;

const RightContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-bottom: 15px;

  @media (max-width: 735px) {
    width: 100%;
    justify-content: center;
  }
`;

const TextCardNoTitle = ({
  props,
  cta_card_subtitle,
  cta_card_title,
  cta_card_button_text,
  cta_card_button_colour,
  cta_card_button_link,
  card_title_underline_color,
  cta_title_underline_color,
  cta_card_button_link_target,
}) => (
  <TextBlockContainer>
    <LeftContent>
      <TextBlockTopLeftText>{cta_card_subtitle}</TextBlockTopLeftText>
    </LeftContent>
    <RightContent>
      <ButtonNotUnderlined
        buttonText={cta_card_button_text}
        color={cta_card_button_colour}
        arrowColor={cta_card_button_colour}
        link={cta_card_button_link}
        target={cta_card_button_link_target}
      />
    </RightContent>
  </TextBlockContainer>
);

export default TextCardNoTitle;
